export default function loadScript(url) {
  return new Promise((resolve, reject) => {
    jQuery.getScript({
      url,
      success: () => {
        resolve();
      },
      error: () => {
        reject(new Error(`unable to load script: ${url}`));
      },
    });
  });
}
