const ATTRIBUTE_NAME = 'data-loading-class';
const LOADABLES_SELECTOR = 'img';

function findLoadableElement(element) {
  if (element.matches(LOADABLES_SELECTOR)) {
    return element;
  }

  const loadable = element.querySelector(LOADABLES_SELECTOR);
  if (!loadable) {
    throw new Error('no loadable element found');
  }

  return loadable;
}

export default function initImageLoader(parentElement) {
  parentElement.querySelectorAll(`[${ATTRIBUTE_NAME}]`).forEach((element) => {
    const loadableElement = findLoadableElement(element);

    if (loadableElement.complete) {
      return;
    }

    const className = element.getAttribute(ATTRIBUTE_NAME);

    element.classList.add(className);

    loadableElement.addEventListener(
      'load',
      () => {
        element.classList.remove(className);
      },
      { once: true }
    );

    loadableElement.addEventListener(
      'error',
      () => {
        element.classList.remove(className);
      },
      { only: true }
    );
  });
}
