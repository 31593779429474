import { throttle } from 'lodash';
import { isExtraSmallDevice, isSmallDevice } from './device_size';

const CONTAINER_ID = 'lightbox_foreground';

function updateContainerOffset() {
  const container = document.getElementById(CONTAINER_ID);
  if (!container) return;

  container.style.setProperty('--offset', `${window.scrollY}px`);
}

function refreshLightbox() {
  if (isExtraSmallDevice() || isSmallDevice()) {
    return;
  }

  updateContainerOffset();
}

export default throttle(refreshLightbox, 20);

// FIXME: Allow swipe left/right navigation
