function onKeyUp(event) {
  if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
    return;
  }

  const elements = document.querySelectorAll(`[data-hotkey="${event.code}"]`);
  if (!elements.length) {
    return;
  }

  event.preventDefault();

  elements.forEach((element) => {
    element.click();
  });
}

export default function init() {
  document.addEventListener('keyup', onKeyUp);
}
