$(document).on('click', '[data-scroll]', function (event) {
  event.preventDefault();

  const $link = $(this);
  const options = $link.data('scroll');

  const target = options?.target || $link.attr('href');
  const targetElem = document.querySelector(target);
  const rect = targetElem.getBoundingClientRect();
  const top = rect.top + window.scrollY;

  window.scrollTo({ top, behavior: 'smooth' });
});
