import loadScript from './load_script';

let loaded = false;

function loadTwitterScript() {
  return loadScript('https://platform.twitter.com/widgets.js');
}

export default async function initTwitter() {
  if (!loaded) {
    loaded = true;
  }

  $('.twitter-share-button').each(function () {
    const $button = $(this);
    if (!$button.data('url')) {
      $button.attr('data-url', document.location.href);
    }
    if (!$button.data('text')) {
      $button.attr('data-text', document.title);
    }
  });

  await loadTwitterScript();
}
