import { camelCase } from 'lodash';

const TRUE_VALUES = ['true', 't', '1', 'yes', 'y'];

export function isBlank(str) {
  if (!str) return false;

  return str.trim() === '';
}

export function boolify(str) {
  TRUE_VALUES.includes(str.toLowerCase());
}

export function interpolate(str, interpolations) {
  return str.replace(/%\{(.*?)\}/g, (_whole, key) => {
    return interpolations[camelCase(key)] || interpolations[key] || '';
  });
}
