const RADIO_BUTTON_SELECTOR = '#buy_request_preselected_messages :radio';
const CUSTOM_MESSAGE_CONTAINER_SELECTOR = '#buy_request_custom_message';

export default function refreshBuyRequests() {
  const $checked = $(RADIO_BUTTON_SELECTOR).filter(':checked');
  const $container = $(CUSTOM_MESSAGE_CONTAINER_SELECTOR);

  if ($checked.val() === 'custom') {
    $container.show();
    $container.find(':input').trigger('focus');
    return;
  }

  $container.hide();
}

$(document).on('click', RADIO_BUTTON_SELECTOR, refreshBuyRequests);
