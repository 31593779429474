import throttle from "lodash/throttle";
import { isExtraSmallDevice } from "./device_size";

const ACTIVE_CLASS_NAME = 'ui-active';
const INACTIVE_CLASS_NAME = 'ui-inactive';
const ELEMENTS_SELECTOR = '[data-track-ui-inactivity]';
const TIMEOUT = 5000;

let timer = null;
let active = false;

function getTrackedElements() {
  return document.querySelectorAll(ELEMENTS_SELECTOR);
}

function hasOpenDropdown() {
  return !!document.querySelector('.dropdown.open');
}

function deactivate() {
  if (hasOpenDropdown() || isExtraSmallDevice()) return;

  active = false;

  getTrackedElements().forEach((element) => {
    element.classList.add(INACTIVE_CLASS_NAME);
    element.classList.remove(ACTIVE_CLASS_NAME);
  })
}

function activate() {
  clearTimeout(timer);

  active = true;

  getTrackedElements().forEach((element) => {
    element.classList.add(ACTIVE_CLASS_NAME);
    element.classList.remove(INACTIVE_CLASS_NAME);
  })

  timer = setTimeout(deactivate, TIMEOUT)
}

function toggle() {
  if (active) {
    deactivate();
  } else {
    activate();
  }
}

const throttledActivate = throttle(activate, 250);

window.addEventListener('turbolinks:load', activate);
window.addEventListener('mousemove', throttledActivate);
window.addEventListener('keydown', throttledActivate);
window.addEventListener('scroll', throttledActivate);
window.addEventListener('click', toggle)
