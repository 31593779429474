import initSocialPlugins from './social_plugins';
import initTrackingPlugins from './tracking';

const COOKIE_BANNER_SELECTOR = '#cookie_banner';
const COOKIE_POLICY_STORAGE_KEY = 'thirdPartyCookiePolicy';
const DEFAULT_THIRD_PARTY_COOKIE_POLICY = 'essential';
const PERMISSIVE_THIRD_PARTY_COOKIE_POLICY = 'all';

async function initThirdPartyPlugins() {
  return Promise.all([initTrackingPlugins(), initSocialPlugins()]);
}

function applyPolicy(policy) {
  $('body').attr('data-cookie-policy', policy);
  if (policy !== PERMISSIVE_THIRD_PARTY_COOKIE_POLICY) return;

  initThirdPartyPlugins();
}

export default function handleThirdPartyCookies() {
  const policy = window.localStorage.getItem(COOKIE_POLICY_STORAGE_KEY);
  applyPolicy(policy || DEFAULT_THIRD_PARTY_COOKIE_POLICY);
  if (policy) return;

  $(COOKIE_BANNER_SELECTOR).removeClass('hidden');
}

$(document).on('click', '[data-third-party-cookie-policy]', function () {
  const policy = $(this).data('thirdPartyCookiePolicy');
  applyPolicy(policy);
  $(COOKIE_BANNER_SELECTOR).addClass('hidden');
  window.localStorage.setItem(COOKIE_POLICY_STORAGE_KEY, policy);
});
