const SELECTOR = '[data-toast]';
const VISIBLE_CLASS = 'toast--visible';

let timer;

export default function toast(message, duration = 2500) {
  return new Promise((resolve, reject) => {
    const container = document.querySelector(SELECTOR);
    if (!container) {
      reject(new Error('toast container not found'));
    }

    const hideToast = () => {
      teardown();
      container.classList.remove(VISIBLE_CLASS);
    };

    const teardown = () => {
      container.removeEventListener('click', hideToast, true);

      if (!timer) return;
      clearTimeout(timer);
      timer = null;
    };

    teardown();

    const textContainer = document.querySelector('.toast__inner');
    textContainer.textContent = message;
    container.classList.add(VISIBLE_CLASS);

    container.addEventListener('click', hideToast, true);

    timer = setTimeout(() => {
      hideToast();
      resolve();
    }, duration);
  });
}
