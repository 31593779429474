import { throttle, shuffle as shuffleCollection, times, last } from 'lodash';
import { isExtraSmallDevice } from './device_size';

class CoverCarousel {
  static ACTIVE_ITEM_CLASS = 'cover-carousel__item--active';
  static IMAGE_SELECTOR = '.cover-carousel__image';
  static ITEM_SELECTOR = '.cover-carousel__item';
  static LOADED_ITEM_CLASS = 'cover-carousel__item--loaded';
  static NEXT_ITEM_CLASS = 'cover-carousel__item--next';
  static PREV_ITEM_CLASS = 'cover-carousel__item--prev';
  static RUNNING_CONTAINER_CLASS = 'cover-carousel--running';

  #order = [];
  #isRunning = false;
  activeIndex = 0;
  #imagesLoaded = [];

  constructor($container, { autostart = false, shuffle = false }) {
    this.onItemPlayed = this.onItemPlayed.bind(this);

    this.$container = $container;
    this.$items = this.$container.find(CoverCarousel.ITEM_SELECTOR);

    const order = times(this.$items.length);
    if (shuffle) {
      this.#order = shuffleCollection(order);
    } else {
      this.#order = order;
    }

    this.activeIndex = this.#order[0] ?? 0;

    this.#buildImagesLoadedPromises();
    this.#updateContainerClasses();
    this.#updateItemClasses();

    if (autostart) {
      this.start();
    }
  }

  get $activeItem() {
    return this.#getItem(this.activeIndex);
  }

  get nextIndex() {
    const i = (this.#order.indexOf(this.activeIndex) + 1) % this.#order.length;
    return this.#order[i];
  }

  get $nextItem() {
    return this.#getItem(this.nextIndex);
  }

  get prevIndex() {
    let current = this.#order.indexOf(this.activeIndex);
    if (current === 0) {
      return last(this.#order);
    }
    return this.#order[current - 1];
  }

  get $prevItem() {
    return this.#getItem(this.prevIndex);
  }

  start() {
    if (this.#isRunning) return;

    $(document).on(
      'animationend',
      CoverCarousel.ITEM_SELECTOR,
      this.onItemPlayed
    );

    this.#isRunning = true;
    this.#updateContainerClasses();
    this.#slideTo(this.activeIndex);
  }

  pause() {
    if (!this.#isRunning) return;

    $(document).off(
      'animationend',
      CoverCarousel.ITEM_SELECTOR,
      this.onItemPlayed
    );

    this.#isRunning = false;
    this.#updateContainerClasses();
  }

  stop() {
    this.pause();
    this.#slideTo(0);
  }

  destroy() {
    this.stop();
  }

  onItemPlayed() {
    this.slideToNext();
  }

  slideToNext() {
    return this.#slideTo(this.nextIndex);
  }

  async #slideTo(index) {
    await this.#imagesLoaded[index];
    this.activeIndex = index;
    this.#updateItemClasses();
  }

  #getItem(index) {
    return this.$items.eq(index);
  }

  #updateItemClasses() {
    this.$items.removeClass([
      CoverCarousel.PREV_ITEM_CLASS,
      CoverCarousel.NEXT_ITEM_CLASS,
      CoverCarousel.ACTIVE_ITEM_CLASS,
    ]);

    this.$prevItem.addClass(CoverCarousel.PREV_ITEM_CLASS);
    this.$activeItem.addClass(CoverCarousel.ACTIVE_ITEM_CLASS);
    this.$nextItem.addClass(CoverCarousel.NEXT_ITEM_CLASS);
  }

  #updateContainerClasses() {
    if (this.#isRunning) {
      this.$container.addClass(CoverCarousel.RUNNING_CONTAINER_CLASS);
    } else {
      this.$container.removeClass(CoverCarousel.RUNNING_CONTAINER_CLASS);
    }
  }

  #buildImagesLoadedPromises() {
    const promises = [];

    this.$items.each(function () {
      const $item = $(this);
      const image = $item.find(CoverCarousel.IMAGE_SELECTOR).get(0);

      const promise = new Promise((resolve, reject) => {
        const onComplete = () => {
          image.removeEventListener('load', onLoad);
          image.removeEventListener('error', onError);
          $item.addClass(CoverCarousel.LOADED_ITEM_CLASS);
        };

        const onLoad = () => {
          onComplete();
          resolve();
        };

        const onError = () => {
          onComplete();
          reject(new Error('unable to load image'));
        };

        image.addEventListener('load', onLoad);
        image.addEventListener('error', onError);

        if (image.complete) {
          onLoad();
        }
      });

      promises.push(promise);
    });

    this.#imagesLoaded = promises;
  }
}

function refreshCarousel() {
  return $('[data-ride="coverCarousel"]').each(function () {
    const $carousel = $(this);
    let carousel = $carousel.data('coverCarousel');

    if (carousel && isExtraSmallDevice()) {
      $carousel.removeData('coverCarousel');
      carousel.destroy();
      carousel = null;
      return;
    }

    if (!carousel && !isExtraSmallDevice()) {
      carousel = new CoverCarousel($carousel, {
        autostart: true,
        shuffle: true,
      });
      window.__CAROUSEL__ = carousel;
      $carousel.data('coverCarousel', carousel);
    }
  });
}

export default throttle(refreshCarousel, 200);
