import loadScript from './load_script';

let loaded = false;

function loadPinterestScript() {
  return loadScript('https://assets.pinterest.com/js/pinit.js');
}

export default async function initPinterest() {
  delete window['PIN_' + ~~(new Date().getTime() / 864e5)];
  await loadPinterestScript();

  loaded = true;
}
