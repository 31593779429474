import { parseInt } from 'lodash';

const GRID_SELECTOR = '[data-behavior="masonry"]';
const ITEM_SELECTOR = '[data-behavior="masonry-item"]';
const CONTENT_SELECTOR = '[data-behavior="masonry-content"]';

function resizeItem(grid, item) {
  const gridStyle = window.getComputedStyle(grid);
  const rowHeight = parseInt(gridStyle.getPropertyValue('grid-auto-rows'));
  const rowGap = parseInt(gridStyle.getPropertyValue('grid-row-gap'));

  const contentHeight = item
    .querySelector(CONTENT_SELECTOR)
    .getBoundingClientRect().height;

  const rowSpan = Math.floor((contentHeight + rowGap) / (rowHeight + rowGap));

  let gridRowEnd = '';
  if (!Number.isNaN(rowSpan)) {
    gridRowEnd = `span ${rowSpan}`;
  }

  item.style.gridRowEnd = gridRowEnd;
}

export default function refreshMasonry() {
  const grid = document.querySelector(GRID_SELECTOR);
  if (!grid) return;

  const { masonryClass } = grid.dataset;
  if (masonryClass) {
    grid.classList.add(masonryClass);
  }

  document.querySelectorAll(ITEM_SELECTOR).forEach((item) => {
    resizeItem(grid, item);
  });
}
