import { get, chain, omit } from 'lodash';
import { interpolate } from './utils/string';

const VALID_OPTIONS = ['scope', 'locale', 'default', 'fallbacks'];

export class I18nError extends Error {
  constructor(message) {
    super(`I18n error: ${message}`);
  }
}

export function currentLocale() {
  return gtg.i18n.locale;
}

export function translate(keypath, options = {}) {
  if (!keypath) {
    throw new I18nError('no keypath defined');
  }

  if (!gtg.i18n.translations) {
    throw new I18nError('no translations found');
  }

  const interpolations = omit(options, VALID_OPTIONS);
  const opts = chain(options)
    .pick(VALID_OPTIONS)
    .defaults({ locale: gtg.i18n.locale, fallbacks: true })
    .value();

  let fallbacks = {};
  if (opts.fallbacks && gtg.i18n.fallbacks[opts.locale]) {
    fallbacks = gtg.i18n.fallbacks[opts.locale];
  }
  if (fallbacks.length === 0) {
    fallbacks[opts.locale] = [opts.locale];
  }

  let translation = null;
  for (const locale of fallbacks) {
    translation = get(gtg.i18n.translations[locale], keypath);
    if (translation) {
      break;
    }
  }

  if (translation) {
    return interpolate(translation, interpolations);
  } else if (opts.default) {
    return interpolate(opts.default, interpolations);
  }

  return keypath;
}

export function t(keypath, options = {}) {
  return translate(keypath, options);
}
