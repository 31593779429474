const BASE_URL = 'https://stats.spdns.de';
const DOWNLOAD_LINK_SELECTOR = '.download';
const DOWNLOAD_PATH_PATTERN = /\/download\//;
const SITE_ID = 5;

let loaded = false;

function embedMatomoScript() {
  const firstScript = document.getElementsByTagName('script')[0];
  if (!firstScript) {
    throw new Error('no script found');
  }

  const matomoScript = document.createElement('script');
  matomoScript.src = `${BASE_URL}/matomo.js`;
  matomoScript.type = 'text/javascript';
  matomoScript.async = true;

  firstScript.parentNode.insertBefore(matomoScript, firstScript);
}

let prevUrl;

function trackPageView() {
  const paq = (window._paq = window._paq || []);

  const currentUrl = document.location.href;

  if (prevUrl !== currentUrl) {
    paq.push(['setReferrerUrl', prevUrl]);
  }

  paq.push(['setCustomUrl', currentUrl]);
  paq.push(['setDocumentTitle', document.title]);
  paq.push(['trackPageView']);
  paq.push(['enableLinkTracking']);

  prevUrl = currentUrl;
}

function isDownloadUrlOnSameSite(url) {
  try {
    const uri = new URL(url);
    return (
      uri.host === document.location.host &&
      !!uri.pathname.match(DOWNLOAD_PATH_PATTERN)
    );
  } catch (e) {
    return false;
  }
}

// Workaround because Matomo did not track download on pages navigated to
// through a Turbolinks link
function trackDownload(event) {
  const el = event.target.closest(DOWNLOAD_LINK_SELECTOR);
  if (!el) return;

  const url = el.href;
  if (!url || !isDownloadUrlOnSameSite(url)) return;

  const paq = (window._paq = window._paq || []);
  paq.push(['trackLink', url, 'download']);
}

export default function initMatomo() {
  if (loaded) return;
  loaded = true;

  const paq = (window._paq = window._paq || []);
  paq.push(['setTrackerUrl', `${BASE_URL}/matomo.php`]);
  paq.push(['setSiteId', SITE_ID]);
  paq.push(['setDownloadClasses', 'matomo-download']);

  embedMatomoScript();

  prevUrl = document.location.href;

  window.addEventListener('turbolinks:load', () => {
    trackPageView();
  });

  window.addEventListener('click', (event) => {
    trackDownload(event);
  });
}
