import { translate } from '../shared/i18n';
import toast from './toast';

$(document).on('click', '[data-copy-to-clipboard]', async function (event) {
  event.preventDefault();

  const $link = $(this);
  const attrName = $link.data('copyToClipboard');
  if (!attrName) return;

  const value = $link.attr(attrName);
  let message;
  try {
    await navigator.clipboard.writeText(value);
    message = translate('javascript.toasts.copied_to_clipboard');
  } catch (e) {
    message = translate('javascript.toasts.unable_to_copy_to_clipboard');
  }
  toast(message);
});
