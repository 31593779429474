export function getSize() {
  const detector = window.getComputedStyle(document.body, ':root');
  return detector.getPropertyValue('--breakpoint').trim();
}

export function isExtraSmallDevice() {
  return getSize() === 'xs';
}

export function isSmallDevice() {
  return getSize() === 'sm';
}

export function isMediumDevice() {
  return getSize() === 'md';
}

export function isLargeDevice() {
  return getSize() === 'lg';
}
