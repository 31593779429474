import { throttle } from 'lodash';

const SELECTOR = '.back-to-top';
const SCROLLED_CLASS = 'back-to-top--scrolled';

function refreshBackToTop() {
  document.querySelectorAll(SELECTOR).forEach((element) => {
    if (window.scrollY === 0) {
      element.classList.remove(SCROLLED_CLASS);
    } else {
      element.classList.add(SCROLLED_CLASS);
    }
  });
}

export default throttle(refreshBackToTop, 200);
