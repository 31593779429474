import './styles/index.scss';
import './scroll';
import './copy_to_clipboard';
import refreshBackToTop from './back_to_top';
import handleThirdPartyCookies from './third_party_cookies';
import initImageLoader from './image_loader';
import initHotkey from './hotkey';
import initTooltips from './tooltip';
import refreshLightbox from './lightbox';
import refreshBuyRequests from './buy_requests';
import refreshCarousel from './cover_carousel';
import refreshMasonry from './masonry';
import './ui_inactivity';

initHotkey();

document.addEventListener('turbolinks:load', () => {
  initImageLoader(document);
  refreshCarousel();
  refreshBuyRequests();
  handleThirdPartyCookies();
  initTooltips();
  refreshBackToTop();
  refreshLightbox();
  refreshMasonry();
});

window.addEventListener('scroll', () => {
  refreshBackToTop();
  refreshLightbox();
});

window.addEventListener('resize', () => {
  refreshCarousel();
  refreshLightbox();
  refreshMasonry();
});
