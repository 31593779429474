const TOOLTIP_SELECTOR = '[data-tooltip]';

export default function initTooltip() {
  $(TOOLTIP_SELECTOR).each(function () {
    const $tooltip = $(this);
    let options = $tooltip.data('tooltip');
    if (!options) {
      return;
    } else if (options === true) {
      options = {};
    }
    $tooltip.tooltip(options);
  });
}
